import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { deepClone } from 'fast-json-patch/module/core';

import { CardRouting } from '@cards-models/card-routing';

import {
  AddActiveCard,
  ChangeLastCard,
  NavigateToCard,
  NavigateToPreviousCard,
  ResetCard,
  ResetCardToDefault,
  SetActiveCard,
  SetDisableNavigation,
  UpdateCardObject,
  UpdateCardObjects,
} from './card.actions';

export class CardStateModel {
  active!: CardRouting | null;
  history!: CardRouting[];
  disableNavigation = false;
}

@State<CardStateModel>({
  name: 'card',
  defaults: {
    active: null,
    history: [],
    disableNavigation: false,
  },
})
@Injectable()
export class CardState {
  constructor(private readonly store: Store) {}

  @Selector()
  static getActiveCard(state: CardStateModel): CardRouting | null {
    return state.active;
  }

  @Selector()
  static getActiveCardObject(state: CardStateModel): Record<string, any> | null | undefined {
    return state.active?.object;
  }

  @Selector()
  static getCardHistory(state: CardStateModel): CardRouting[] {
    return state.history;
  }

  @Action(ResetCard)
  resetCard({ setState }: StateContext<CardStateModel>): void {
    setState({
      active: null,
      history: [],
      disableNavigation: false,
    });
  }

  @Action(ResetCardToDefault)
  resetCardToDefault({ getState, setState }: StateContext<CardStateModel>): void {
    const state = getState();
    const defaultCard = state.history[0];
    setState({
      active: defaultCard,
      history: [defaultCard],
      disableNavigation: false,
    });
  }

  @Action(SetActiveCard)
  setActiveCard({ setState, getState }: StateContext<CardStateModel>, { id, route, object, objects }: SetActiveCard): void {
    const state = getState();
    if (!state?.disableNavigation) {
      const card = {
        id,
        route,
        object,
        objects,
      };
      setState({
        history: [card],
        active: card,
        disableNavigation: false,
      });
    }
  }

  @Action(SetDisableNavigation)
  setDisableNavigation({ patchState }: StateContext<CardStateModel>, { payload }: SetDisableNavigation): void {
    patchState({
      disableNavigation: payload,
    });
  }

  @Action(AddActiveCard)
  addActiveCard({ patchState, getState }: StateContext<CardStateModel>, { id, route, object }: AddActiveCard): void {
    const state = getState();
    if (!state.disableNavigation) {
      const previousActive = state.active;
      if (!previousActive) {
        this.store.dispatch(new SetActiveCard(id, route, object));
        return;
      }
      const card = {
        id,
        route,
        object,
      };
      patchState({
        history: [...state.history, card],
        active: card,
      });
    }
  }

  @Action(ChangeLastCard)
  changeLastCard({ patchState, getState }: StateContext<CardStateModel>, { id, route, object }: ChangeLastCard): void {
    const state = getState();
    if (!state.disableNavigation) {
      const previousActive = state.active;
      if (!previousActive) {
        this.store.dispatch(new SetActiveCard(id, route, object));
        return;
      }
      const history = deepClone(state.history);
      if (history.length <= 1) {
        this.store.dispatch(new AddActiveCard(id, route, object));
        return;
      }
      const card = {
        id,
        route,
        object,
      };

      history[history.length - 1] = card;
      patchState({
        history,
        active: card,
      });
    }
  }

  @Action(NavigateToCard)
  navigateToCard({ setState, getState }: StateContext<CardStateModel>, { payload }: NavigateToCard): void {
    const state = getState();
    if (!state.disableNavigation) {
      const rawHistory = [...state.history];
      const i = rawHistory.findIndex((card) => card.id === payload);
      if (!~i) return;
      const activeCard = rawHistory[i];
      rawHistory.length = i + 1;
      setState({
        history: rawHistory,
        active: activeCard,
        disableNavigation: false,
      });
    }
  }

  @Action(NavigateToPreviousCard)
  navigateToPreviousCard({ setState, getState }: StateContext<CardStateModel>): void {
    const state = getState();
    if (!state.disableNavigation) {
      const rawHistory = [...state.history];
      const i = rawHistory.length - 2;
      const activeCard = rawHistory[i];
      rawHistory.length = i + 1;
      setState({
        history: rawHistory,
        active: activeCard,
        disableNavigation: false,
      });
    }
  }

  @Action(UpdateCardObject)
  updateCardObject({ setState, getState }: StateContext<CardStateModel>, { id, object }: UpdateCardObject): void {
    const state = getState();
    const objectArray: CardRouting[] = deepClone(state.history);
    const activeCard = state.active;
    if (!activeCard) return;

    const i = objectArray.findIndex((x) => x.id === id);
    if (~i) {
      if (object) objectArray[i].object = object;
      else objectArray[i].object = null;
    }

    const myObject = activeCard.id === id ? object : activeCard.object;

    const myActive: CardRouting = {
      id: activeCard.id,
      route: activeCard.route,
      object: myObject,
    };
    setState({
      history: objectArray,
      active: myActive,
      disableNavigation: false,
    });
  }

  @Action(UpdateCardObjects)
  updateCardObjects({ setState, getState }: StateContext<CardStateModel>, { id, objects }: UpdateCardObjects): void {
    const state = getState();
    const objectArray: CardRouting[] = deepClone(state.history);
    const activeCard = state.active;
    if (!activeCard) return;

    const i = objectArray.findIndex((x) => x.id === id);
    if (!~i) return;

    objectArray[i].objects = objects;

    const myActive: CardRouting = {
      id,
      route: activeCard.route,
      object: objects[0],
      objects,
    };
    setState({
      history: objectArray,
      active: myActive,
      disableNavigation: false,
    });
  }
}
